const siteMetadata = {
    title: `DalSoft Ltd`,
    siteUrl: `http://localhost`,
    capitalizeTitleOnHome: false,
    logo: `/images/svg/icon-purple.svg`,
    icon: `/images/svg/icon-purple.svg`,
    titleImage: `/images/wall.jpg`,
    ogImage: `/images/wall.jpg`,
    twoColumnWall: true,
    cookiePolicy: true,
    introTag: `Development consultancy services`,
    description: `We're an established, highly capable development consultancy specialising in full stack development and DevOps.`,
    about: "",
    author: `@dalsoft`,
    blogItemsPerPage: 10,
    portfolioItemsPerPage: 10,
    darkmode: true,
    switchTheme: true,
    navLinks: [
        {
            name: "HOME",
            url: "/",
        },
        {
            name: "PRODUCTS",
            url: "/products",
        },
        {
            name: "ABOUT",
            url: "/about",
        },
        {
            name: "BLOG",
            url: "/blog",
        },
        {
            name: "PORTFOLIO",
            url: "/portfolio",
        },
        {
            name: "CONTACT",
            url: "/contact",
        },
    ],
    footerLinks: [
        {
            name: "PRIVACY POLICY",
            url: "/privacy-policy",
        },
        {
            name: "GITHUB",
            url: "https://github.com/DalSoft",
        },
    ],
    social: [
        {
            name: "Twitter",
            icon: "/images/x.svg",
            url: "https://twitter.com/DalSoft",
        },
        {
            name: "Linked In",
            icon: "/images/LinkedIn.svg",
            url: "https://www.linkedin.com/in/dalsoft/",
        },
        {
            name: "Stack Overflow",
            icon: "/images/StackOverflow.svg",
            url: "https://stackoverflow.com/users/324497/dalsoft",
        },
        {
            name: "GitHub",
            icon: "/images/GitHub.svg",
            url: "https://github.com/DalSoft",
        }
    ],
    contact: {
        // leave empty ('') or false to hide form
        api_url: "https://dalsoft-website-backend-functions.azurewebsites.net/api/contact-us-form",
        description: "Get in touch to discuss your project and discover how we can help you achieve your digital goals with our expertise, innovation, and an unwavering commitment to excellence.",
        mail: "solutions@dalsoft.co.uk",
        phone: "",
        address: "W8a Knoll Business Centre\n325-327 Old Shoreham Road,\nHove, BN3 7GS, United Kingdom",
    },
    utterances: {
        repo: "dalsoft/dalsoft-website-comments",
        issueTerm: "title",
        label: "comment",
    }
    
}

const beforeContactFormSubmit = data => {
    // Code 0 - success
    // Code 1 - Name
    // Code 2 - Email
    // Code 3 - Message
    // Code 4 - Other
    const errors = []

    if (data.name.trim().length < 2) {
        errors.push({
            code: 1,
            message: "Enter a name",
        })
    }

    if (!data.email.match(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/)) {
        errors.push({
            code: 2,
            message: "Enter a valid email address",
        })
    }

    if (data.message.trim().length < 15) {
        errors.push({
            code: 3,
            message: "Enter a message with atleast 15 characters",
        })
    }

    if (errors.length > 0)
        return {
            result: false,
            errors: errors,
        }

    return {
        data: {
            name: data.name,
            email: data.email,
            message: data.message,
        },
        result: true,
    }
}

const contactFormSubmit = async (api, data) => {
    let res: any = await fetch(api, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })

    res = await res.json()

    if (res.success) {
        return {
            result: true,
        }
    }
    return {
        result: false,
        ...res,
    }
}

const defaults = {
    disqus: null,
    twoColumnWall: true,
    darkmode: false,
    switchTheme: true,
    capitalizeTitleOnHome: true,
    cookiePolicy: false
}

Object.keys(defaults).forEach(item => {
    if (siteMetadata[item] === undefined) {
        siteMetadata[item] = defaults[item]
    }
})

export { siteMetadata, beforeContactFormSubmit, contactFormSubmit }
